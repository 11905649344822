import { UNIT_PRICE_DECIMAL_PLACES } from '@/constants'
import { DateTime, type Order, type PurchaseOrder } from '@quotetome/materials-api'

type DateValue = DateTime | undefined | null | number
type NumberValue = string | number | null | undefined

function formatDate(date: DateValue, formatStr: string) {
  if (date && (date as DateTime).format) {
    return (date as DateTime).format(formatStr)
  }
  if (date) {
    return DateTime.fromUnix(date as number).format(formatStr)
  }
  return '—'
}

export const dateFormat = 'MMM D'
export const dateTimeFormat = `${dateFormat}, h:mm A`
export const dateYearFormat = 'MMM D, YYYY'
export const isoDateFormat = 'YYYY-MM-DD'
export const onlyTimeFormat = 'h:mm A'

export const conciseDate = (date: DateValue) => {
  return formatDate(date, dateFormat)
}

export const currency = (value: NumberValue, decimalPlaces = 2) => {
  if (value == null || Number.isNaN(Number(value))) {
    return '—'
  }
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: decimalPlaces,
    roundingMode: 'halfEven',
  }).format(Number(value))
}

export const dateMonth = (date: DateValue) => {
  return formatDate(date, dateFormat)
}

export const dateTime = (date: DateValue) => {
  return formatDate(date, dateTimeFormat)
}

export const dateYear = (date: DateValue) => {
  return formatDate(date, dateYearFormat)
}

export const decimal = (
  value: NumberValue,
  { maximumFractionDigits } = { maximumFractionDigits: UNIT_PRICE_DECIMAL_PLACES },
) => {
  return new Intl.NumberFormat('en-CA', {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits: 2,
    roundingMode: 'halfEven',
  }).format(Number(value))
}

export const timeOrDateTime = (date: number | undefined | null) => {
  if (date) {
    const format = DateTime.fromUnix(date).isSame(new DateTime(), 'day') ? onlyTimeFormat : dateTimeFormat
    return formatDate(date, format)
  }
  return '—'
}

export const isoDate = (date: DateValue) => {
  return formatDate(date, isoDateFormat)
}

export const monthDay = (date: DateValue) => {
  return formatDate(date, dateFormat)
}

export const objectDeliveryDate = (subject?: Order | PurchaseOrder) => {
  if (subject && subject.delivery_date && subject.delivery_date.format) {
    if (subject.delivery_time === 'sp') {
      return subject.delivery_date.format(dateTimeFormat)
    }

    const format = `${dateFormat} [(${subject.delivery_time === 'am' ? 'Morning' : 'Afternoon'})]`

    return subject.delivery_date.format(format)
  }

  return '—'
}
