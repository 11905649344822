<template>
  <v-menu
    :close-on-content-click="false"
    content-class="qtm-border"
    max-width="450"
    min-width="450"
    offset-y
    :model-value="menu"
    @update:model-value="toggleMenu"
  >
    <template v-slot:activator="{ props: menuProps }">
      <v-tooltip :disabled="!description" location="top">
        <template v-slot:activator="{ props: tooltipProps }">
          <span v-if="disabled" v-bind="tooltipProps" class="text-mid-grey" v-text="modelValue" />
          <span v-else v-bind="{ ...menuProps, ...tooltipProps }" class="cursor-pointer text-interactive">
            {{ modelValue || 'Add Cost Code' }}
            <v-icon v-if="modelValue" color="interactive">
              {{ icon }}
            </v-icon>
          </span>
        </template>
        {{ description }}
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <qtm-text-field
          v-model="search"
          clearable
          hide-details
          placeholder="Search Cost Codes"
          prepend-inner-icon="mdi-magnify"
        />
      </v-card-title>
      <v-card-text class="cost-code-radios qtm-border-bottom">
        <qtm-radio-group
          v-model="valueProxy"
          hide-details
          item-key="id"
          item-value="cost_code"
          :items="filteredCostCodes"
        />
      </v-card-text>
      <v-card-actions class="py-5">
        <qtm-checkbox v-model="applyToAll" label="Apply to all" />
        <v-spacer />
        <qtm-btn tertiary @click="toggleMenu(false)">
          Cancel
        </qtm-btn>
        <qtm-btn @click="apply">
          Apply
        </qtm-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
export interface Props {
  costCodes?: any[]
  disabled?: boolean
  modelValue?: string
}

const props = withDefaults(defineProps<Props>(), {
  costCodes: () => [],
  disabled: false,
  modelValue: ''
})
const emit = defineEmits(['all', 'update:model-value'])

const applyToAll = ref(false)
const menu = ref(false)
const search = ref('')
const valueProxy = ref(props.modelValue)

const costCodesWithLabel = computed(() => {
  return props.costCodes.map(c => ({ ...c, id: String(c.id), label: `${c.cost_code} - ${c.description}` }))
})
const description = computed(() => {
  if (props.modelValue) {
    return props.costCodes.find(costCode => costCode.cost_code === props.modelValue)?.description
  }

  return undefined
})
const filteredCostCodes = computed<any[]>(() => {
  const cleanSearch = search.value?.trim().toLowerCase()

  if (cleanSearch) {
    return costCodesWithLabel.value.filter(c => c.label.toLowerCase().includes(cleanSearch))
  }

  return costCodesWithLabel.value
})
const icon = computed(() => (menu.value ? 'mdi-chevron-up' : 'mdi-chevron-down'))

const toggleMenu = (open: boolean) => {
  applyToAll.value = false
  valueProxy.value = props.modelValue
  menu.value = open
}
const apply = () => {
  if (applyToAll.value) {
    emit('all', valueProxy.value)
  }
  else {
    emit('update:model-value', valueProxy.value)
  }

  toggleMenu(false)
}
</script>

<style lang="scss" scoped>
.cost-code-radios {
  max-height: 300px;
  overflow-y: auto;
}
</style>
