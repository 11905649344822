<template>
  <qtm-table :headers="headers">
    <draggable
      :list="items"
      animation="150"
      class="qtm-body"
      ghost-class="ghost"
      handle=".handle"
      item-key="id"
      tag="tbody"
      @start="onDragStart"
      @end="onDragEnd"
      @change="$emit('sort:items', $event)"
    >
      <template v-slot:item="{ element: item }">
        <cart-items-row
          ref="rows"
          :boms="boms"
          :cost-codes="costCodes"
          :disabled="disabled"
          :force-cost-code-select="forceCostCodeSelect"
          :edit-only="editOnly"
          :include-units-column-in-rentals="includeUnitsColumnInRentals"
          :item="item"
          :item-number-field="itemNumberField"
          :rearrangeable="rearrangeable && items.length > 1"
          :rental="rental"
          :require-cost-codes="requireCostCodes"
          :taxes="taxes"
          :transformable="transformable"
          :units="units"
          :validate-price="validatePrice"
          :validate-units="validateUnits"
          :with-cost-code="withCostCodes"
          :with-prices="withPrices"
          @cost-code-changed="$emit('cost-code-changed', $event)"
          @insert="$emit('insert', { item, location: $event })"
          @tax-changed="$emit('tax-changed', $event)"
          @remove="$emit('remove', $event)"
        />
      </template>
    </draggable>
    <template v-slot:empty>
      <div>
        <!-- Remove empty state -->
      </div>
    </template>
  </qtm-table>
</template>

<script setup lang="ts">
import draggable from 'vuedraggable'
import CartItemsRow from '@/components/orders/cart-items-row.vue'
import QtmTable from '@/components/qtm-table.vue'

export interface Props {
  boms?: any[]
  costCodes?: any[]
  disabled?: boolean
  editOnly?: boolean
  forceCostCodeSelect?: boolean
  itemNumberField?: string
  items: any[]
  rearrangeable?: boolean
  rental?: boolean
  requireCostCodes?: boolean
  taxes?: any[]
  transformable?: boolean
  units?: any[]
  validatePrice?: boolean
  validateUnits?: boolean
  withCostCodes?: boolean
  withPrices?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  boms: () => [],
  costCodes: () => [],
  disabled: false,
  forceCostCodeSelect: false,
  itemNumberField: 'index',
  rearrangeable: false,
  rental: false,
  requireCostCodes: false,
  taxes: () => [],
  units: () => [],
  validatePrice: true,
  validateUnits: false,
  withCostCodes: false,
  withPrices: false,
})
defineEmits(['cost-code-changed', 'insert', 'remove', 'sort:items', 'tax-changed'])

const headers = computed(() => {
  let headerArr = [
    { title: '#', cellClass: 'text-right' },
    { title: props.rental ? 'Rental items' : 'Purchase items' },
    { title: 'Qty' },
    props.rental ? { title: 'Duration', colspan: 2 } : { title: 'U/M' },
  ]

  if (props.rental && includeUnitsColumnInRentals.value) {
    headerArr.push({ title: 'U/M' })
  }

  if (props.withPrices) {
    if (props.rental) {
      headerArr = headerArr.concat(['Day rate', 'Week rate', 'Month rate'].map(title => ({ title })))
    }
    else {
      headerArr.push({ title: 'Unit price' })
    }

    headerArr.push({ title: 'Total' })
  }

  if (props.withCostCodes) {
    headerArr.push({ title: 'Cost code' })
  }

  if (props.taxes.length) {
    headerArr.push({ title: 'Tax' })
  }

  headerArr.push({ title: 'Comment' })

  if (!props.disabled && !props.editOnly) {
    headerArr.push({ title: '' })
  }

  return headerArr.map(header => ({ ...header, value: header.title }))
})

const includeUnitsColumnInRentals = computed(() => {
  const hasItemsWithNoUnits = props.items.filter(item => !item.unit).length > 0

  return !(props.rental && props.validateUnits && units.length && hasItemsWithNoUnits)
})

const rows = ref<InstanceType<typeof CartItemsRow>[]>()
const isValid = () => !rows.value || !rows.value.length || rows.value.every(row => row.isValid())
const onDragStart = (event: any) => {
  const parent = event.item.closest('.overflow-x-auto')

  parent?.classList.remove('overflow-x-auto')
  parent?.classList.add('overflow-x-hidden')
  parent?.classList.add('no-handle')
}
const onDragEnd = (event: any) => {
  const parent = event.item.closest('.overflow-x-hidden')

  parent?.classList.add('overflow-x-auto')
  parent?.classList.remove('overflow-x-hidden')
  parent?.classList.remove('no-handle')
}
const focusLastItem = () => rows.value.focusDescription()

defineExpose({ focusLastItem, isValid })
</script>
