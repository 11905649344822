import { defineStore } from 'pinia'
import { sentry } from '@/plugins/sentry'
import type { User } from '@quotetome/materials-api'

function userHasPerm(user: User | undefined, permCodename: string) {
  return user && user.qtm_permissions?.some(perm => perm.codename === permCodename)
}

interface State {
  user: User | undefined,
  authToken: string | undefined,
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: undefined,
    authToken: undefined
  }),
  getters: {
    allowCustomCostCodes(state) {
      return state.user?.company?.allow_custom_cost_codes
    },

    canCreateJobsite(state) {
      return userHasPerm(state.user, 'qtm_can_create_jobsite')
    },

    canCreatePO(state) {
      return userHasPerm(state.user, 'qtm_can_create_purchase_order')
    },

    canGetQuotes(state) {
      return userHasPerm(state.user, 'qtm_can_get_quotes')
    },

    canManageCompany(state) {
      return userHasPerm(state.user, 'qtm_can_manage_company')
    },

    canManageInvoices(state) {
      return userHasPerm(state.user, 'qtm_can_use_invoices')
    },

    canManageUsers(state) {
      return userHasPerm(state.user, 'qtm_can_manage_users')
    },

    canUseExpenses(state) {
      return userHasPerm(state.user, 'qtm_can_use_expenses')
    },

    canUseRequisitions(state) {
      return userHasPerm(state.user, 'qtm_can_use_requisitions')
    },

    inSetupMode(state) {
      return state.user?.company?.setup_mode
    },

    isAuthenticated(state) {
      return !!state.authToken
    },

    isStaff(state) {
      return state.user && state.user.is_staff
    },

    purchasingEmail(state) {
      if (state.user && state.user.company && state.user.company.purchasing_email) {
        return state.user.company.purchasing_email
      }

      return ''
    },

    userCompany(state) {
      return state.user ? state.user.company : undefined
    },
  },
  actions: {
    setUser(user: User | undefined) {
      this.user = user
      sentry.setUser(user)
    },

    updateUser(changes: any) {
      Object.assign(this.user, changes)
      sentry.setUser(this.user)
    },

    setAuthToken(token: string | undefined) {
      if (token) {
        localStorage.setItem('authToken', token)
      }
      else {
        localStorage.removeItem('authToken')
      }
      this.authToken = token
    },
  }
})
