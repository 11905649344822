<template>
  <v-menu
    v-model="showProjects"
    :close-on-content-click="false"
    content-class="qtm-border"
  >
    <template v-slot:activator="{ props: activator }">
      <v-btn
        v-bind="activator"
        color="secondary"
        data-test="change-project-btn"
        icon="mdi-pencil"
        :loading="loading"
        size="small"
        variant="text"
      />
    </template>

    <jobsite-select
      v-model="altProject"
      autofocus
      class="project-autocomplete"
      hide-details
      :user="user"
      @update:model-value="onChange"
    />
  </v-menu>
</template>

<script setup lang="ts">
import type { Jobsite, User } from '@quotetome/materials-api'
import JobsiteSelect from '@/components/jobsites/jobsite-select.vue'

export interface Props {
  project?: Jobsite
  user?: User
}

defineProps<Props>()
const emit = defineEmits(['change'])

const altProject = ref<Jobsite>()
const loading = ref(false)
const showProjects = ref(false)

const onChange = async (project: Jobsite) => {
  emit('change', project)
  showProjects.value = false

  await nextTick()
  altProject.value = undefined
}
</script>

<style lang="scss">
.project-autocomplete {
  width: 400px;
}
</style>
