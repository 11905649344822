<template>
  <qtm-autocomplete
    ref="searchInput"
    v-bind="$attrs"
    v-model:search="searchText"
    :disabled="disabled"
    hide-details="auto"
    no-filter
    return-object
    :items="itemsBySection"
    :loading="loading"
    :maxlength="maxlength"
    :placeholder="placeholderText"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" :search="searchText" />
    </template>
    <template v-slot:item="{ item, props: activator }">
      <div v-if="item.raw?.header" class="px-4 pt-1 text-caption font-weight-bold text-mid-dark-grey">
        <span class="bg-light-grey px-1 rounded-sm text-uppercase" v-text="item.raw.header" />
      </div>
      <v-list-item v-else v-bind="activator" />
    </template>
    <template v-slot:no-data>
      <v-list-item class="missing-item-row">
        <v-label v-if="!loading && searchText && searchText.length > 2">
          No matches found
        </v-label>
        <v-label v-else>
          Start typing to begin search
        </v-label>
      </v-list-item>
    </template>
    <template v-slot:append-item>
      <div v-if="results.length" class="my-5 text-center">
        <qtm-btn v-if="!maxSearchResultsReached" tertiary @click="getMoreSearchResults">
          Load More Results
        </qtm-btn>
        <div v-else class="text-mid-light-grey">
          No more search results found
        </div>
      </div>
    </template>
  </qtm-autocomplete>
</template>

<script setup lang="ts">
import debounce from 'lodash.debounce'
import { SEARCH_MAX_LENGTH } from '@/constants'

interface Section {
  filter: (item: any) => boolean
  header: string
}

export interface Props {
  appendItems?: any[]
  disabled?: boolean
  document: string
  filter?: (item: any) => boolean
  filters?: Record<string, any>
  limit?: number | string
  maxlength?: number | string
  maxWidth?: number | string
  placeholder?: string
  sections?: Section[]
}

const props = withDefaults(defineProps<Props>(), {
  appendItems: () => [],
  disabled: false,
  filter: undefined,
  filters: undefined,
  limit: 10,
  maxlength: SEARCH_MAX_LENGTH,
  maxWidth: undefined,
  placeholder: undefined,
  sections: undefined,
})

const loading = ref(false)
const offset = ref(0)
const results = ref<any[]>([])
const searchText = ref('')

const endpoint = computed(() => `${props.document}s`)
const items = computed(() => results.value.concat(props.appendItems))
const itemsBySection = computed(() => {
  if (!props.sections) {
    return items.value
  }

  let bySection: any[] = []

  props.sections.forEach(section => {
    const sectionItems = items.value.filter(section.filter)

    if (sectionItems.length) {
      bySection.push({ header: section.header })
      bySection = bySection.concat(sectionItems)
    }
  })

  return bySection
})
const maxSearchResultsReached = computed(() => (
  results.value.length !== 0
  && results.value.length !== (offset.value + Number(props.limit))
))
const placeholderText = computed(() => (
  props.placeholder || `${props.document.charAt(0).toUpperCase()}${props.document.slice(1)} Search`
))

const getQueryParams = () => ({
  pagination: { limit: props.limit, offset: offset.value },
  search: searchText.value,
  ...props.filters,
})

const search = debounce(() => {
  if (props.disabled || !isValidSearch()) {
    return
  }

  offset.value = 0

  getSearchResults()
}, 350)

const getMoreSearchResults = () => {
  offset.value += props.limit
  getSearchResults()
}

watch(searchText, search)

const { $api, $error } = useNuxtApp()

const getSearchResults = async () => {
  loading.value = true
  try {
    let response = await $api.v1.search[endpoint.value as keyof typeof $api.v1.search](getQueryParams())

    if (props.filter) {
      response = response.filter(props.filter)
    }

    if (offset.value) {
      results.value = results.value.concat(response)
    }
    else {
      results.value = response
    }
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const isValidSearch = () => {
  if (!searchText.value || searchText.value.length < 2) {
    results.value = []

    return false
  }

  return true
}
</script>
