<template>
  <document-search
    v-bind="$attrs"
    data-test="supplier-search-input"
    document="supplier"
    :filters="{ include_all: includeAll || undefined, user }"
    :item-title="itemText"
    limit="30"
    :placeholder="placeholder"
    :sections="sections"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </document-search>
</template>

<script setup lang="ts">
import type { Vendor } from '@quotetome/materials-api'
import DocumentSearch from '@/components/search/document-search.vue'

export interface Props {
  includeAll?: boolean
  placeholder?: string
  user?: number
}

withDefaults(defineProps<Props>(), {
  placeholder: 'Start typing supplier name...',
  user: undefined,
})

const itemText = (item: Vendor) => {
  if (item?.name) {
    return `${item.name} - ${item.city}`
  }
  return ''
}

const sections = [
  {
    filter: (item: any) => item.preferred,
    header: 'Preferred Suppliers',
  },
  {
    filter: (item: any) => !item.preferred,
    header: 'QTM Suppliers',
  }
]
</script>
