<template>
  <qtm-autocomplete
    v-bind="$attrs"
    v-model:search="searchText"
    clearable
    hide-details="auto"
    :items="users"
    :item-title="displayUser"
    :loading="loading"
    menu-icon=""
    no-filter
    placeholder="Search by name"
    prepend-inner-icon="mdi-magnify"
    return-object
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-label v-if="searchText && searchText.length">
          No matching users found
        </v-label>
        <v-label v-else>
          Start typing to begin your search
        </v-label>
      </v-list-item>
    </template>
  </qtm-autocomplete>
</template>

<script setup lang="ts">
import debounce from 'lodash.debounce'
import type { Company, User } from '@quotetome/materials-api'

export interface Props {
  company?: Company
}

const props = defineProps<Props>()

const loading = ref(false)
const searchText = ref('')
const users = ref<User[]>([])

const sanitizedSearch = computed(() => searchText.value?.trim() ?? '')

const { $api, $error } = useNuxtApp()

const fetchUsers = debounce(async () => {
  if (sanitizedSearch.value.length < 3) {
    users.value = []
    return
  }

  loading.value = true
  try {
    const response = await $api.v1.users.list({
      company_family: props.company?.id,
      limit: 20,
      offset: 0,
      search: sanitizedSearch.value
    } as any)

    users.value = response.data
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}, 350)

watch(searchText, fetchUsers)

const displayUser = (user?: User) => (user ? `${user.first_name} ${user.last_name} (${user.email})` : '')
</script>
