import mixpanel from 'mixpanel-browser'
import { useAuthStore } from '@/stores/auth'

interface Tracker {
  track: (eventName: string, eventProperties?: any) => void
  logIn: (userId: number | string) => void
  logOut: () => void
  startReplay: () => void
  stopReplay: () => void
}

export const tracker: Tracker = {
  track: (event, data) => console.info('Tracking stub: ', event, data),
  logIn: (id) => console.info('Tracking login stub: ', id),
  logOut: () => console.info('Tracking logout stub'),
  startReplay: () => console.info('Start Session Replay'),
  stopReplay: () => console.info('Stop Session Replay')
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()

  if (config.public.MIXPANEL_TOKEN) {
    mixpanel.init(config.public.MIXPANEL_TOKEN, {
      record_block_class: "",
      record_block_selector: "",
      record_collect_fonts: true,
      record_mask_text_class: "",
      record_mask_text_selector: "",
    })

    tracker.track = (eventName, eventProperties) => {
      const properties = eventProperties || {}
      const user = authStore.user
      const replay_url = mixpanel.get_session_replay_url()

      if (user) {
        properties.distinct_id = user.id
      }

      if (replay_url) {
        properties.replay_url = replay_url
      }

      if (user && user.company) {
        const companyId = user.company.id
        mixpanel.track_with_groups(eventName, properties, { company_id: properties.company_id ?? companyId })
      }
      else {
        mixpanel.track(eventName, properties)
      }
    }
    tracker.logIn = (userId: number | string) => {
      mixpanel.identify(userId.toString())
    }
    tracker.logOut = () => {
      mixpanel.reset()
    }
    tracker.startReplay = () => {
      mixpanel.start_session_recording()
    }
    tracker.stopReplay = () => {
      mixpanel.stop_session_recording()
    }
  }

  return {
    provide: { tracker }
  }
})
