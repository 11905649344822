<template>
  <qtm-content-block v-if="showTable || canAddNewAttachments" title="Attachments">
    <table v-if="showTable" class="attachments-table qtm-body">
      <thead>
        <tr class="text-mid-grey">
          <td v-for="header in headers" :key="header" class="pb-4" v-text="header" />
        </tr>
        <tr>
          <td :colspan="headers.length">
            <v-divider />
          </td>
        </tr>
      </thead>
      <attachments-row
        v-for="element in attachmentsByCategory"
        :key="element.label"
        :bottom-divider="!element.last || invoices.length > 0 || otherAttachments.length > 0"
        :attachments="element.attachments"
        :label="element.label"
      />
      <invoice-attachments-row
        v-if="invoices.length && po"
        :bottom-divider="!!otherAttachments.length"
        :invoices="invoices"
        :order="order"
        :po="po"
      />
      <attachments-row v-if="otherAttachments.length" :attachments="otherAttachments" label="Supporting Documents" />
    </table>
    <order-attachments
      v-if="canAddNewAttachments"
      v-model="newAttachments"
      content-type="rfqs.purchaseorder"
      hide-attachments
      :object-id="po.id"
      remove-title
    />
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Attachment, Invoice, Order, PurchaseOrder } from '@quotetome/materials-api'
import AttachmentsRow from '@/components/attachments/attachments-row.vue'
import InvoiceAttachmentsRow from '@/components/invoices/invoice-attachments-row.vue'
import OrderAttachments from '@/components/orders/order-attachments.vue'

export interface Props {
  invoices?: Invoice[]
  order: Order
  po?: PurchaseOrder
}

const props = withDefaults(defineProps<Props>(), {
  invoices: () => [],
  po: undefined
})

const PO_CATEGORY = 2
const headers = ['Category', 'File', 'Upload Date']

const newAttachments = ref([])

const attachmentsByCategory = computed(() => {
  let available: { label: string, attachments: Attachment[], last?: boolean }[] = []

  if (props.order.isDraft) {
    available = [
    { label: 'Request', attachments: props.order.attachments },
    { label: 'Supplier Quote', attachments: props.order.quotes[0].attachments }
    ].filter(element => element.attachments.length)
  }
  else if (props.po) {
    available = [
      { label: 'Request', attachments: props.po.attachments.rfq_attachments },
      { label: 'Supplier Quote', attachments: props.po.attachments.quote_attachments },
      {
        label: 'Purchase Order',
        attachments: props.po.attachments.po_attachments.filter(attachment => attachment.category === PO_CATEGORY),
        visibleCutoff: 1,
      },
      { label: 'Receiving', attachments: props.po.attachments.receipt_attachments },
    ].filter(element => element.attachments.length)
  }

  if (available.length) {
    available[available.length - 1].last = true
  }

  return available
})
const canAddNewAttachments = computed(() => !props.order.isDraft && props.po)
const otherAttachments = computed(() => {
  let attachments: Attachment[] = []

  if (props.po) {
    attachments = props.po?.attachments.po_attachments
    .filter(attachment => attachment.category !== PO_CATEGORY)
    .concat(newAttachments.value)
  }

  return attachments
})
const showTable = computed(() => {
  return attachmentsByCategory.value.length || props.invoices.length || otherAttachments.value.length
})
</script>

<style lang="scss" scoped>
.attachments-table {
  width: 100%;
}

.attachments-table tr:first-child td:nth-child(2) {
  padding-left: 16px;
}
</style>
