// https://github.com/nuxt-community/sentry-module/issues/358#issuecomment-1016983543

import * as Sentry from '@sentry/vue'

export const sentry = {
  addBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
  captureException: (exception: any) => Sentry.captureException(exception),
  setContext: (n: any, context: any) => Sentry.setContext(n, context),
  setExtra: (key: string, value: any) => Sentry.setExtra(key, value),
  setUser: (user: any) => Sentry.setUser(user),
  setTag: (tagName: any, value: any) => Sentry.setTag(tagName, value),
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp

  Sentry.init({
    app: [vueApp],
    dsn: config.public.SENTRY_DSN,
    logErrors: false,
    tracesSampleRate: Number(config.public.SENTRY_TRACES_SAMPLE_RATE) || 1.0,
    debug: Boolean(config.public.SENTRY_ENABLE_DEBUG) || false,
    environment: config.public.ENV_TYPE || 'dev',
    enabled: config.public.ENV_TYPE !== 'dev' || config.public.FORCE_SENTRY,
    release: `qtm-app-front-end@${config.public.NPM_PACKAGE_VERSION}`,
  })

  nuxtApp.provide('sentry', sentry)
})
