import axios from 'axios'

const apiHostByEnvironment = {
  'dev': window.location.hostname,
  'staging': 'api-staging.quotetome.com',
  'demo': 'api-demo.quotetome.com',
  'prod': 'api.quotetome.com',
}

export const axiosInstance = axios.create()

const MAX_REQUESTS = 3
const INTERVAL = 10
let pendingRequests = 0

axiosInstance.interceptors.request.use(config => {
  return new Promise((resolve, _reject) => {
    const interval = setInterval(() => {
      if (pendingRequests < MAX_REQUESTS) {
        pendingRequests += 1
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL)
  })
})

axiosInstance.interceptors.response.use(response => {
  pendingRequests = Math.max(0, pendingRequests - 1)

  return Promise.resolve(response)
}, error => {
  pendingRequests = Math.max(0, pendingRequests - 1)

  if (error.response?.status === 525) {
    const config = error.config

    if (!config.retryCounter) {
      config.retryCounter = 1
    }
    else {
      config.retryCounter += 1
    }

    if (config.retryCounter <= 3) {
      return new Promise((resolve, _reject) => {
        setTimeout(() => {
          resolve(axiosInstance.request(config))
        }, 100)
      })
    }
  }

  return Promise.reject(error)
})

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  let host = 'localhost'
  let port
  let protocol = 'https'

  if (import.meta.client) {
    host = config.public.CLIENT_API_HOST
      || apiHostByEnvironment[config.public.ENV_TYPE as keyof typeof apiHostByEnvironment]
    port = config.public.CLIENT_API_PORT || (process.env.NODE_ENV === 'development' ? 8000 : undefined)

    const useHttps = process.env.NODE_ENV === 'production' && host !== 'localhost'
    protocol = config.public.CLIENT_API_PROTOCOL || (useHttps ? 'https' : 'http')
  }

  let baseURL
  if (port) {
    baseURL = `${protocol}://${host}:${port}/`
  }
  else {
    baseURL = `${protocol}://${host}/`
  }

  axiosInstance.defaults.baseURL = baseURL
  axiosInstance.defaults.xsrfCookieName = 'csrftoken'
  axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'
  axiosInstance.defaults.headers['X-Platform'] = 'WEB'

  nuxtApp.provide('axios', axiosInstance)
})
