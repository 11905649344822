<template>
  <qtm-autocomplete
    v-bind="$attrs"
    v-model:search="searchText"
    data-test="jobsite-select"
    :disabled="disabled"
    hide-details="auto"
    :item-title="itemName"
    :items="items"
    :loading="loading"
    :no-data-text="noDataText"
    no-filter
    placeholder="Start typing to begin search"
    return-object
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template v-slot:prepend-item>
      <div v-if="items.length && !loading" class="pl-4 qtm-body-small text-mid-grey">
        Showing {{ items.length }} out of {{ count }} search results
      </div>
    </template>

    <template v-slot:append-item>
      <v-list-item class="qtm-label text-interactive" @click="showJoin = true">
        <v-icon class="mt-n1 mr-2" icon="mdi-plus" />
        Join
      </v-list-item>
    </template>
    <template v-slot:no-data>
      <v-list-item class="text-mid-grey">
        <v-label>
          {{ noDataText }}
        </v-label>
      </v-list-item>
    </template>
  </qtm-autocomplete>
  <join-jobsite-dialog v-model="showJoin" @joined="joined" />
</template>

<script setup lang="ts">
import debounce from 'lodash.debounce'
import type { Jobsite, User } from '@quotetome/materials-api'
import JoinJobsiteDialog from '@/components/jobsites/join-jobsite-dialog.vue'

export interface Props {
  disabled?: boolean
  limit?: number | string
  params?: any
  user?: User
  userRequired?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  limit: 30,
  params: undefined,
  user: undefined,
  userRequired: true,
})
const emit = defineEmits(['update:model-value'])

watch(() => props.user, () => {
  if (props.disabled) {
    return
  }

  emit('update:model-value', null)
})

const count = ref(0)
const items = ref<Jobsite[]>([])
const loading = ref(false)
const searchText = ref('')
const showJoin = ref(false)

const noDataText = computed(() => {
  if (props.userRequired && !props.user) {
    return 'Select a user first'
  }

  if (loading.value) {
    return 'Searching...'
  }

  if (searchText.value) {
    return 'No results found'
  }

  return 'Start typing to begin search'
})

const { $api, $error } = useNuxtApp()

const add = (jobsite: Jobsite) => items.value.push(jobsite)
const search = debounce(async () => {
  if ((!props.user && props.userRequired) || props.disabled) {
    return
  }

  loading.value = true
  try {
    const results = await $api.v1.jobsites.list({
      ...props.params,
      limit: props.limit,
      offset: 0,
      search: searchText.value,
      user: props.user
    })

    count.value = results.count
    items.value = results.data
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}, 250)

watch(searchText, search, { immediate: true })

const itemName = (site: Jobsite) => {
  return `${site.project_number} - ${site.name} - ${site.address}, ${site.city}, ${site.province}, ${site.country}`
}
const joined = (jobsite: Jobsite) => {
  items.value.push(jobsite)
  emit('update:model-value', jobsite)
}
const remove = (jobsite: Jobsite) => {
  items.value = items.value.filter(item => item.id !== jobsite.id)
}

defineExpose({ add, remove })
</script>
